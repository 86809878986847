<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
	name: 'App',

	setup() {
		// TODO	
	}
})
</script>

<style lang="scss" scoped>
#app {
	display: flex;
	width: 100vw;
	max-width: 100vw;
	height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	// background-color: #ff0;
	// border: 1px solid #f00;
	// justify-content: center;
	// align-items: center;

	@media only screen and (max-width: 480px) {
		overflow-y: auto;
	}
}
</style>