import Vue from 'vue'

import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import Quasar from 'quasar/src/vue-plugin.js';
import VueCompositionAPI from '@vue/composition-api'

Vue.use(Quasar, {
	config: {},
	
	plugins: {
	}
})

Vue.use(VueCompositionAPI)